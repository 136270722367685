<template>
  <v-main>
    <v-container :style="{ paddingTop: user ? '' : '10vh' }">
      <v-card flat max-width="480" class="mx-auto">
        <v-card-title v-if="!user" class="text-center">
          <v-list-item-content class="primary--text">
            <v-list-item-title class="display-1">
              fire<span class="font-weight-bold">ful</span>
            </v-list-item-title>
            <v-list-item-subtitle class="caption">
              version {{ require('@/../package.json').version }} βeta
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-card-title>
        <v-card-text>
          <router-view />
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'CleanLayout',
  computed: {
    ...mapState(['user'])
  }
}
</script>
